<template>
  <!-- 查询供应商所有申请的付款单 -->
  <el-table
    :data="tableData"
    @selection-change="handleSelectionChange"
    style="width: 100%"
  >
    <el-table-column type="selection" width="55" />
    <el-table-column prop="sq_date" label="日期" width="180" />
    <el-table-column prop="id_no" label="单号" width="180" />
    <el-table-column prop="sq_amount" label="金额" />
    <el-table-column prop="fk_text" label="摘要" />
  </el-table>
</template>

<script>
//import axios from "axios";

export default {
  props: ["supplier_info_id", "project_id"],
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    //加载供应商所有申请的付款单
    this.loadfklist();
  },
  watch: {
    supplier_info_id() {
      //加载供应商所有申请的付款单
      this.loadfklist();
    },
  },
  methods: {
    //加载供应商所有申请的付款单
    loadfklist() {
      //加载供应商所有申请的付款单
      this.axios
        .get("admin/FkRequest/indexsupplier", {
          params: {
            supplier_info_id: this.supplier_info_id,
            project_id: this.project_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //多选表格ID
    handleSelectionChange(val) {
      this.$emit('select-change',val);
    },   
  },
};
</script>



<style>
</style>
