<template>
  <!--发票内容识别控件-->
  <div>
    <input
      name="image"
      type="file"
      accept="image/png,image/gif,image/jpeg"
      @change="update"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {};
  },

  methods: {
    // 添加请求头
    update(e) {
      // 上传照⽚
      let file = e.target.files[0];
      /* eslint-disable no-undef */
      let param = new FormData(); // 创建form对象
      param.append("image", file); // 通过append向form对象添加数据
      param.append("chunk", "0"); // 添加form表单中其他数据
      console.log(param.get("file")); // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: window.sessionStorage.getItem("token"),
        },
      };
      // 添加请求头
      axios
        .post("admin/InputInvoice/upload", param, config)
        .then((res) => {
          console.log(res.data);
           this.$emit('get-invoicetext',res.data);
        })
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        });
    },
  },
};
</script>

<style scoped></style>
