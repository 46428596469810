<template>
  <!-- 新增进项发票 -->
  <div class="table-responsive mt-5">
    <router-link :to="{ name: 'invoice_inputinvoice_list' }">
      <el-button size="mini">回到发票列表</el-button>
    </router-link>
<el-button size="mini" @click="invoicedialogVisible=true">发票识别</el-button>
    <div class="text-center">
      <h3>发票录入</h3>
    </div>
    <table class="table table-bordered table-sm dark">
      <tbody>
        <tr>
          <td colspan="8" style="background-color: rgba(152, 150, 245, 0.05)">
            基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>日期</small></td>
          <td>
            <el-date-picker
              v-model="formdata.billing_date"
              type="date"
              placeholder=""
              size="small"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            ></el-date-picker>
          </td>
          <td class="text-center align-middle"><small>发票代码</small></td>
          <td>
            <el-input
              v-model="formdata.invoice_code"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>发票号码</small></td>
          <td>
            <el-input
              v-model="formdata.invoice_no"
              placeholder=""
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>货品名称</small></td>
          <td>
            <el-input
              v-model="formdata.goods_naem"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>税率</small></td>
          <td>
            <el-select
              v-model="formdata.tax_rate"
              placeholder="Select"
              style="width: 240px"
            >
              <el-option key="1" label="1%" :value="1" />
              <el-option key="3" label="3%" :value="3" />
              <el-option key="5" label="5%" :value="5" />
              <el-option key="6" label="6%" :value="6" />
              <el-option key="9" label="9%" :value="9" />
              <el-option key="11" label="11%" :value="11" />
              <el-option key="13" label="13%" :value="13" />
              <el-option key="0" label="0%" :value="0" />
            </el-select>
          </td>
          <td class="text-center align-middle"><small>发票种类</small></td>
          <td>
            <el-select
              v-model="formdata.tax_class"
              placeholder="Select"
              style="width: 240px"
              ref="taxclass"
            >
              <el-option key="1" label="普票" :value="1" />
              <el-option key="2" label="专票" :value="2" />
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>金额</small></td>
          <td>
            <el-input
              v-model="formdata.amount_money"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>税额</small></td>
          <td>
            <el-input
              v-model="formdata.tax_amount"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>价税合计</small></td>
          <td>
            <el-input
              v-model="formdata.total_price_tax"
              placeholder=""
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>成本类别</small></td>
          <td>
            <select-cbfl @select-change="selectChange"></select-cbfl>
          </td>
          <td class="text-center align-middle"><small>供应商</small></td>
          <td>
            <supplier-info
              :category_ids="formdata.category_id"
              @select-change="SelectClick"
            ></supplier-info>
          </td>
          <td class="text-center align-middle"><small>对应付款单</small></td>
          <td>
            <el-button @click="supplierfkrequesvisible = true"
              >付款单</el-button
            >
            {{ requestnos }}
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>购买方名称</small></td>
          <td>
            <invoicebuys-select
              @select-change="invoicebuysselect"
              :placeholder="invoicebuysplace"
            ></invoicebuys-select>
          </td>
          <td class="text-center align-middle"></td>
          <td></td>
          <td class="text-center align-middle"></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <!-- 确认新增合同弹框 -->
    <el-popconfirm
      title="您确定要添加发票吗？"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-type="primary"
      @confirm="addcontract"
    >
      <template #reference>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 48%"
          :disabled="!issaveok"
          >保存新增</el-button
        >
      </template>
    </el-popconfirm>

    <div>
      <el-button :disabled="issaveok" size="mini" @click="dialogVisible = true"
        >批量上传</el-button
      >

      <el-dialog v-model="dialogVisible" width="50%" @closed="getFileList">
        <div class="text-center">
          <file-upload
            :fileclass="fileclass"
            :prdclassid="prdclassid"
            urlpath="admin/AccessoryFile"
            filedir="invoice"
          ></file-upload>
        </div>
      </el-dialog>
      <el-table :data="tableData.data">
        <el-table-column prop="file_name" label="文件名称" width="300" />
        <el-table-column prop="create_time" label="上传时间" width="150" />
        <el-table-column label="操作" width="150" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button
                size="small"
                plain
                @click="openPdfFile(scope.row.url_path)"
                >查看</el-button
              >
              <el-button
                size="small"
                plain
                type="danger"
                @click="deletePdfFile(scope.row.id)"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <!-- 查看PDF文件 -->
  <el-dialog
    v-model="pdfurlVisible"
    width="100%"
    fullscreen
    @closed="pdfurl = ''"
  >
    <div class="text-center" style="height: 2000px">
      <iframe :src="pdfurl" width="100%" height="100%"></iframe>
    </div>
  </el-dialog>
  <!-- 选择发票对应的付款单 -->
  <el-dialog v-model="supplierfkrequesvisible" width="60%">
    <supplier-fkreques
      :supplier_info_id="formdata.supplier_id"
      :project_id="formdata.project_id"
      @select-change="selectsrowid"
    ></supplier-fkreques>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="supplierfkrequesvisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!--发票文本识别-->
  <el-dialog v-model="invoicedialogVisible" width="100%" @closed="getFileList">
    <div class="text-center">
      <inputinvoice-text
       @get-invoicetext="getInvoiceText"
      ></inputinvoice-text>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessageBox } from "element-plus";
import axios from "axios";
import supplierfkrequestVue from "../../../components/fkrequext/supplierfkrequest.vue";
import inputinvoiceTextVue from "../../../components/inputinvoice/index.vue";
export default {
  data() {
    return {
      //发票文本识别模态框
      invoicedialogVisible:false,
      value1: "",
      value2: "",
      value3: "",

      invoicebuysplace: "", //购买方默认值 修改前的值

      formdata: {
        project_id: window.sessionStorage.getItem("project_ids"), //项目ID
        category_id: "", //成本ID
        supplier_id: "", //供应商ID
        invoice_code: "", //发票代码
        invoice_no: "", //发票号码
        amount_money: "", //金额
        tax_amount: "", //税额
        total_price_tax: "", //价税合计
        tax_rate: "", //税率
        billing_date: "", //开票日期
        goods_naem: "", //商品名称
        tax_class: "", //发票类型
        reqinvoices: [], //对应的付款申请单号
        invoice_buys_id: "", //购买方ID
      },
      requestnos: "", //选中的所有付款申请单号
      issaveok: true, //如果增加成功，则新增按钮失效
      //分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票
      //           4-银行回单  5-收款合同  6-收款凭证  7-资金计划附件
      fileclass: "3",
      prdclassid: "", //新增记录ID
      supplier_name: "", //供应商名称

      dialogVisible: false, //是否打开上传模态框
      supplierfkrequesvisible: false, //是否打开供应商付款单模态框

      pdfurl: "", //PDF文件urlf地址
      pdfurlVisible: false, //查看显示PDF文件

      //附件列表数据
      tableData: {
        data: [],
      },
    };
  },
  methods: {
    //单击购买方名称控件事件
    invoicebuysselect(val) {
      console.log("单击购买方名称控件事件", val.id);
      this.formdata.invoice_buys_id = val.id;
    },
    //更改成本分类选项
    selectChange(itme) {
      //计算生成成本ID（/一级分类id/二级分类id/.../)
      let temp = "";
      for (let i = itme.length - 1; i >= 0; i--) {
        temp = itme[i] + "/" + temp;
      }
      temp = "/" + temp;
      this.formdata.category_id = temp;
      this.supplier_name = ""; //清空供应商的名称
    },
    //单击供应商名称表
    SelectClick(row) {
      console.log(row.id);
      this.formdata.supplier_id = row["id"]; //供应商ID
      this.supplier_name = row["supplier_name"]; //供应商名称
    },
    //新增发票登记
    addcontract() {
      axios
        .post("admin/InputInvoice", this.formdata, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then((response) => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          this.issaveok = false; //禁用新增按钮，打开上传按钮
          this.prdclassid = response.data; //获取新增ID
          console.log("新增ID", this.prdclassid);
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取对应记录的附件
    getFileList() {
      axios
        .get("admin/AccessoryFile", {
          params: {
            fileclass_id: this.fileclass,
            record_id: this.prdclassid,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //删除PDF文件 @click="deletePdfFile(scope.row.id)"
    deletePdfFile(rowid) {
      axios
        .delete("admin/AccessoryFile/" + rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
          this.getFileList();
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看PDf
    openPdfFile(url) {
      this.pdfurlVisible = true;
      this.pdfurl = url;
    },
    //多选支付申请单列表
    selectsrowid(val) {
      let rowsdata = [];
      this.requestnos = "";
      val.forEach((element) => {
        rowsdata.push(element.id);
        this.requestnos = element.id_no + "  " + this.requestnos;
      });

      this.formdata.reqinvoices = rowsdata;
    },
    //发票文本识别
    getInvoiceText(val){
        this.formdata.invoice_code= val[1].发票代码 //发票代码
        this.formdata.invoice_no= val[1].发票号码 //发票号码
        this.formdata.amount_money= val[1].不含税金额 //金额
        this.formdata.tax_amount= val[1].发票税额//税额
        this.formdata.total_price_tax= val[1].发票金额 //价税合计
      //  this.formdata.tax_rate= val[1].发票详单[0].税率//税率
        this.formdata.billing_date= val[1].开票日期 //开票日期
       // this.formdata.goods_naem= val[1].发票详单[0] //商品名称
    }
  },
  components: {
    "supplier-fkreques": supplierfkrequestVue,
    "inputinvoice-text": inputinvoiceTextVue,
  },
};
</script>

<style></style>
